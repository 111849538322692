import _ from 'lodash';
import dayjs from 'dayjs';

import { FilterDataType } from './types';
import { formatTime, utcTimeConverLocalTime } from './format';

export const FilterData: FilterDataType = {
  notificationStatus: [
    { value: 0, label: '全部' },
    { value: false, label: '未讀' },
    { value: true, label: '已讀' },
  ],

  noOrYes: [
    { label: '否', value: 1 },
    { label: '是', value: 2 },
  ],

  cashOnDelivery: [
    { label: '否', value: false },
    { label: '是', value: true },
  ],

  businesType: [
    { value: 0, label: 'Unknown' },
    { value: 1, label: 'GroupDelivery' },
    { value: 2, label: 'Fresh' },
  ],

  orderStatus: [
    { value: 0, label: '未分配' },
    { value: 1, label: '已分配' },
    { value: 2, label: '已排單' },
    { value: 3, label: '已推送' },
    { value: 4, label: '開始配送' },
    { value: 5, label: '已取貨' },
    { value: 6, label: '取貨失敗' },
    { value: 7, label: '已送達' },
    { value: 8, label: '送貨失敗' },
    { value: 9, label: '已取消' },
  ],

  source: [
    { value: 0, label: '全部' },
    { value: 1, label: '運單導入' },
    { value: 2, label: '收單API(YM)' },
    { value: 3, label: '收單API(SJ)' },
    { value: 4, label: '收單API(KF)' },
    { value: 5, label: '收單API(OS)' },
  ],

  sex: [
    { value: 0, label: '全部' },
    { value: 1, label: '男' },
    { value: 2, label: '女' },
  ],

  driverStatus: [
    { value: 0, label: '全部' },
    { value: 1, label: '邀請失敗' },
    { value: 2, label: '已邀請' },
    { value: 3, label: '已加入' },
    { value: 4, label: '待審核' },
    { value: 5, label: '已綁定' },
    { value: 6, label: '已停用' },
  ],

  registrationRecord: [
    { value: 0, label: '全部' },
    { value: 1, label: '已報名' },
    { value: 2, label: '已取消' },
    { value: 3, label: '已排單' },
    { value: 4, label: '配送中' },
    { value: 5, label: '已完成' },
  ],

  signupType: [
    { value: 0, label: '全部' },
    { value: 1, label: '自動報名' },
    { value: 2, label: '司機報名' },
  ],

  weeks: [
    { value: 1, label: '週一' },
    { value: 2, label: '週二' },
    { value: 3, label: '週三' },
    { value: 4, label: '週四' },
    { value: 5, label: '週五' },
    { value: 6, label: '週六' },
    { value: 0, label: '週日' },
  ],

  catType: [
    { value: 0, label: '' },
    { value: 1, label: 'car' },
    { value: 2, label: 'van' },
    { value: 3, label: 'suv' },
    { value: 4, label: 'other' },
  ],

  timeOut: [
    { value: 0, label: '不限' },
    { value: 1, label: '已超時' },
    { value: 2, label: '未超時' },
  ],

  markerImagesUploadTime: [
    { value: 0, label: '不限' },
    { value: 1, label: '最近一天' },
    { value: 2, label: '最近兩天' },
  ],

  markerImagesOverheadType: [
    { value: 0, label: '不限' },
    { value: 1, label: '已置頂落貨照' },
    { value: 2, label: '未置頂落貨照' },
    { value: 3, label: '已置頂落貨備註' },
    { value: 4, label: '未置頂落貨備註' },
  ],

  echartType: [
    { value: 1, label: '業務跟進類' },
    { value: 2, label: '數據分析類' },
    { value: 0, label: '其他' },
  ],

  // 今日司機狀態枚舉
  toDayDriverStatus: [
    { label: '超時未開始', value: 0 },
    { label: '超時未上報', value: 1 },
    { label: '正常配送中', value: 2 },
    { label: '超時未完成', value: 3 },
    { label: '已完成配送', value: 4 },
  ],

  // 次日司機狀態枚舉
  nextDayDriverStatus: [
    { label: '未報名', value: 0 },
    { label: '未分配', value: 1 },
    { label: '已分配', value: 2 },
    { label: '已排單', value: 3 },
  ],

  statusOptions: [
    { value: 6, label: 'All' },
    { value: 1, label: 'Planning' },
    { value: 2, label: 'Published' },
    { value: 3, label: 'Inprogress' },
    { value: 4, label: 'Completed' },
  ],

  categoryOptions: [
    { value: -1, label: 'All' },
    { value: 0, label: 'Unknow' },
    { value: 1, label: 'GroupDelivery' },
    { value: 2, label: 'Fresh' },
  ],

  evaluationType: [
    { value: 0, label: '司機評價收件方' },
    { value: 1, label: '收件方評價司機' },
    { value: 2, label: '收件方評價寄件方' },
  ],

  evaluationAuditStatus: [
    { value: -1, label: '全部' },
    { value: 0, label: '待審核' },
    { value: 1, label: '已通過' },
    { value: 2, label: '不通過' },
  ],

  evaluationStatus: [
    { value: 0, label: '全部' },
    { value: 1, label: '司機已評' },
    { value: 2, label: '司機未評' },
    { value: 3, label: '收件人已評' },
    { value: 3, label: '收件人未評' },
  ],

  drivingLicenseOptions: [
    { value: 1, label: 'ClassA' },
    { value: 2, label: 'ClassB' },
    { value: 3, label: 'ClassC' },
    { value: 4, label: 'ClassM' },
    { value: 5, label: 'CDLA' },
    { value: 6, label: 'CDLB' },
    { value: 7, label: 'CDLC' },
  ],

  // 时间戳转时间
  formatDate: (data: string | number, iteratee: string = 'YYYY-MM-DD HH:mm:ss'): string => {
    if (!data) return '-';
    const text = utcTimeConverLocalTime(data as string, iteratee);
    return text.toString();
  },

  // 时间转时间戳
  formTimestamp: (data: string = dayjs().format('YYYY-MM-DD HH:mm:ss')): number => {
    if (!data) data = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const text: any = dayjs(data).valueOf();
    return text;
  },
  // 获取时间的日期部分
  getDate: (data: string | number, iteratee: string = 'YYYY-MM-DD HH:mm:ss'): string => {
    if (_.isEmpty(data)) return '';
    const text = formatTime(data as string, iteratee);
    return text.toString();
  },
};

export function Filter(key: string | number, data?: any[] | string | number, agm?: any): any {
  const dict = FilterData[key as keyof FilterDataType];
  // const dict = FilterData[key];
  if (data === undefined) data = [];

  // 如果类型是function的话，回调
  if (_.isFunction(dict)) {
    return _.isArray(agm) ? dict(data, ...agm) : dict(data, agm);
  }

  // object or array
  if (_.isObject(data) || _.isArray(data)) {
    return dict;
  }

  let item: any = _.isArray(dict) && dict.find((el: any) => el.value === data);

  if (!_.isEmpty(item)) {
    return item.label;
  }

  item = _.isArray(dict) && dict.find((el: any) => el.label === data);
  if (!_.isEmpty(item)) {
    return item.value;
  }

  return data;
}
